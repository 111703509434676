<template>
  <div class="paginator">
    <div class="paginator-control" :class="{ disabled: disablePrev }" @click="handle('prev')">
      <i class="fa fa-chevron-left"></i>
    </div>
    <div v-for="page in allowedPages" :key="page" :class="{active: currentPage === page, 'paginator-control': page !== '...', dots: page === '...'}" @click="currentPage = page">
      {{ page }}
    </div>
    <div class="paginator-control" :class="{ disabled: disableNext }" @click="handle('next')">
      <i class="fa fa-chevron-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'pages'],
  computed: {
    currentPage: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    disableNext () {
      return this.currentPage === this.pages
    },
    disablePrev () {
      return this.currentPage === 1
    },
    allowedPages () {
      if (this.pages <= 8) return this.pages
      const page = this.currentPage
      let pages = []
      if (page <= 3) pages = [1, 2, 3, 4, '...']
      else {
        const start = page < this.pages - 5 ? page : this.pages - 5
        for (let i = start - 2; i < start + 2; i++) {
          if (i < this.pages - 3) pages.push(i)
        }
        pages.push('...')
      }
      for (let i = this.pages - 3; i <= this.pages; i++) {
        pages.push(i)
      }
      return pages
    }
  },
  methods: {
    handle (type) {
      if (type === 'prev' && this.currentPage === 1) {
        return false
      }
      if (type === 'next' && this.currentPage === this.pages) {
        return false
      }

      this.currentPage = type === 'next' ? this.currentPage + 1 : this.currentPage - 1
    }
  }
}
</script>
